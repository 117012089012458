import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { TestingComponent } from './testing/testing.component';
import { MaterialsComponent } from './materials/materials.component';
import { IndividualComponent } from './individual/individual.component';
import { TeamsComponent } from './teams/teams.component';
import { FirmsComponent } from './firms/firms.component';
import { CouponsComponent } from './coupons/coupons.component';
import { DressComponent } from './dress/dress.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}
@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      LandingPageComponent,
      FooterComponent,
      ContactComponent,
      AboutComponent,
      TestingComponent,
      MaterialsComponent,
      IndividualComponent,
      TeamsComponent,
      TeamsComponent,
      FirmsComponent,
      CouponsComponent,
      DressComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
      HttpClientModule,
      ClickOutsideModule,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBdpWWchyIun_sVqebjv9_zCVsFosoRaDU'
      })
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
