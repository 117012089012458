import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { TestingComponent } from './testing/testing.component';
import { MaterialsComponent } from './materials/materials.component';
import { IndividualComponent } from './individual/individual.component';
import { TeamsComponent } from './teams/teams.component';
import { FirmsComponent } from './firms/firms.component';
import { CouponsComponent } from './coupons/coupons.component';
import { DressComponent } from './dress/dress.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'kontakt',
    component: ContactComponent
  },
  {
    path: 'o-nas',
    component: AboutComponent
  },
  {
    path: 'testovani',
    component: TestingComponent
  },
  {
    path: 'materialy-a-technologie',
    component: MaterialsComponent
  },
  {
    path: 'individualni-pristup',
    component: IndividualComponent
  },
  {
    path: 'pro-tymy-a-zavodniky',
    component: TeamsComponent
  },
  {
    path: 'pro-firemni-zakazniky',
    component: FirmsComponent
  },
  {
    path: 'slevove-a-darkove-poukazy',
    component: CouponsComponent
  },
  {
    path: 'oblekame',
    component: DressComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
