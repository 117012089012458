import { Component, OnInit } from '@angular/core';
// import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // animations: [
  //   trigger('dropdownTrigger', [
  //     state('closed', style({
  //       transform: 'translate(calc(100% - 9rem), -100%)',
  //     })),
  //     state('opened', style({
  //       transform: 'translate(calc(100% - 9rem), 0)',
  //     })),
  //     transition('closed<=>opened', animate('200ms')),
  //   ]),
  // ]
})
export class HeaderComponent implements OnInit {

  currentState = 'closed';
  currentLanguage = localStorage.getItem('ostyle_lang');

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

  triggerDropdown() {
    this.currentState = this.currentState === 'closed' ? 'opened' : 'closed';
  }

  changeLanguage() {
    this.triggerDropdown();
    if (this.currentLanguage === 'cz') {
      this.currentLanguage = 'en';
      localStorage.setItem('ostyle_lang', 'en');
      this.translate.use('en');
    } else {
      this.currentLanguage = 'cz';
      localStorage.setItem('ostyle_lang', 'cz');
      this.translate.use('cz');
    }
  }
}
