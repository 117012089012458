import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  lat = 50.724543;
  lng = 15.174452;

  labelOptions = {
    color: 'black',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    text: 'O\'STYLE'
  };

  constructor() { }

  ngOnInit() {
  }

}
