import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { detect } from '../../node_modules/detect-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public browser = detect().name;

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('cz');

     // the lang to use, if the lang isn't available, it will use the current loader to get them
    let currentLang = localStorage.getItem('ostyle_lang');
    if (!currentLang) {
      currentLang = 'cz';
      localStorage.setItem('ostyle_lang', 'cz');
    }
    translate.use(currentLang);
  }
}
