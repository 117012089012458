import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firms',
  templateUrl: './firms.component.html',
  styleUrls: ['./firms.component.scss']
})
export class FirmsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
